































































































































































































import Vue from 'vue';
import ConfirmDialog from '~/components/common/ConfirmDialog.vue';
import ImageViewer from '~/components/common/ImageViewer.vue';
import Moment from '~/components/common/Moment.vue';
import TooltipButton from '~/components/common/TooltipButton.vue';
import {Question, TestInstance} from '~/models';

export default Vue.extend({
  components: {Moment, TooltipButton, ConfirmDialog, ImageViewer},
  data() {
    return {
      confirmDialog: false,
      info: null,
      img: {show: false, path: null},
      imgDialog: {show: false, figures: null},
      testInstanceResponse: null,
    };
  },
  async created() {
    const results = await Promise.all([
      await this.$store.dispatch('instance/fetchTestInstanceById', this.$route.params.id),
      await this.$store.dispatch('instance/getAdditionalTestInstanceInfo', this.$route.params.id),
    ]);
    this.testInstanceResponse = results[0];
    this.info = results[1];
  },
  watch: {
    async '$route.params.id'(newInstanceId) {
      const results = await Promise.all([
        await this.$store.dispatch('instance/fetchTestInstanceById', newInstanceId),
        await this.$store.dispatch('instance/getAdditionalTestInstanceInfo', newInstanceId),
      ]);
      this.testInstanceResponse = results[0];
      this.info = results[1];
    },
  },
  computed: {
    instance(): TestInstance {
      return TestInstance.query().whereId(Number(this.$route.params.id)).withAll()
          .with('test')
          .with('questions.answers|explanationFigures|figures')
          .first();
    },
    headers(): any[] {
      return [
        {text: this.$tc('p.question'), value: 'text'},
      ];
    },
    studentHeaders(): any[] {
      return [
        {text: this.$t('label.name'), value: 'name'},
      ];
    },
    instanceHeaders(): any[] {
      return [
        {text: this.$t('label.student'), value: 'student.fullName'},
        {text: this.$tc('p.correctAnswer', 2), value: 'correctAnswers'},
        {text: this.$t('label.submittedAt'), value: 'finishedAt'},
        {text: this.$t('label.passed'), value: 'passed'},
      ];
    },
    percentage(): number {
      return Math.floor(this.instance.correctAnswers / this.instance.questionAmount * 100);
    },
    hasTriesLeft(): boolean {
      return this.instance.try < this.instance.test.tries;
    },
    isLatestInstance(): boolean {
      return this.instanceIndex === this.info.instances.length - 1;
    },
    isFirstInstance(): boolean {
      return this.instanceIndex === 0;
    },
    instanceIndex(): number {
      return this.info ? this.info.instances.findIndex((instance: any) => instance.id == this.instance.id) : -1;
    },
  },
  methods: {
    getBreadcrumbs(question: Question) {
      if (!this.testInstanceResponse) {
        return null;
      }
      const responseQuestion = this.testInstanceResponse.questions.find((q: any) => q.id === question.id);
      return responseQuestion?.breadCrumbs || [];
    },
    async grantInsight(grantInsight) {
      if (grantInsight) {
        await this.$store.dispatch('instance/grantInsight', this.instance);
      } else {
        await this.$store.dispatch('instance/denyInsight', this.instance);
      }
    },
    getCorrectAnswer(question: Question) {
      return question.answers.find(a => a.isCorrect);
    },
    getProvidedAnswer(question: Question) {
      return this.instance.answers.find(a => a.questionId === question.id);
    },
    isCorrect(question: Question): boolean {
      const correctAnswer = this.getCorrectAnswer(question);
      const providedAnswer = this.getProvidedAnswer(question);
      return correctAnswer?.id === providedAnswer?.id;
    },
    onGiveExtraTryClick() {
      if (this.instance.passed) {
        this.confirmDialog = true;
        return;
      }
      this.giveAdditionalTry();
    },
    async giveAdditionalTry(): Promise<void> {
      await this.$store.dispatch('student/giveAdditionalTry', {
        testId: this.instance.test.id,
        studentId: this.instance.student.id,
      });
      this.info.extraTry = true;
    },
    goToPrevInstance() {
      if (this.instanceIndex > 0) {
        const target = this.info.instances[this.instanceIndex - 1];
        this.$router.push({name: 'test-instance-details', params: {id: target.id}});
      }
    },
    goToNextInstance() {
      if (this.instanceIndex < this.info.instances.length - 1) {
        const target = this.info.instances[this.instanceIndex + 1];
        this.$router.push({name: 'test-instance-details', params: {id: target.id}});
      }
    },
  },
});
