

















import Vue from 'vue';
import TooltipButton from '~/components/common/TooltipButton.vue';

export default Vue.extend({
  name: 'ImageViewer',
  components: {TooltipButton},
  props: {
    disableSelect: {type: Boolean, default: false},
    disableWheel: {type: Boolean, default: false},
    minZoom: {type: Number, default: 30},
    maxZoom: {type: Number, default: 200},
    zoomStep: {type: Number, default: 10},
    src: {type: String, default: ''},
  },
  data() {
    return {
      zoom: 100,
      originalWidth: 0,
      originalHeight: 0,
      contain: true,
      drag: false,
      dragStartX: 0,
      dragStartY: 0,
      x: 0,
      y: 0,
    };
  },
  created() {
    this.$root.$el.addEventListener('mouseup', this.onMouseUp);
  },
  destroyed() {
    this.$root.$el.removeEventListener('mouseup', this.onMouseUp);
  },
  computed: {
    zoomFactor(): number {
      return this.zoom / 100;
    },
    containerRect(): any {
      return (this.$refs.img as HTMLElement).getBoundingClientRect();
    },
    bgWidth(): number {
      return this.originalWidth * this.zoomFactor;
    },
    bgHeight(): number {
      return this.originalHeight * this.zoomFactor;
    },
    imgOffsetX(): number {
      return this.containerRect.width - this.bgWidth;
    },
    imgOffsetY(): number {
      return this.containerRect.height - this.bgHeight;
    },
    style(): any {
      return {
        backgroundSize: this.contain ? 'contain' : `${this.bgWidth}px ${this.bgHeight}px`,
        backgroundImage: `url('${this.src}')`,
        backgroundPosition: this.contain ? 'center' : `${this.x}px ${this.y}px`,
        cursor: this.contain ? 'default' : 'move',
      };
    },
  },
  watch: {
    zoom(newValue, oldValue) {
      this.updateZoom(newValue / oldValue);
    },
    disableSelect: {
      immediate: true,
      handler(disableSelect) {
        (this.$root.$el as HTMLElement).style.userSelect = disableSelect ? 'none' : 'auto';
      },
    },
    src: {
      immediate: true,
      handler(newSrc) {
        const img = new Image();
        img.onload = () => {
          this.originalHeight = img.height;
          this.originalWidth = img.width;
        };
        img.src = newSrc;
        this.x = 0;
        this.y = 0;
        this.contain = true;
        this.zoom = 100;
      },
    },
  },
  methods: {
    zoomIn() {
      if (this.zoom < this.maxZoom) {
        this.zoom += this.zoomStep;
      }
    },
    zoomOut() {
      if (this.zoom > this.minZoom) {
        this.zoom -= this.zoomStep;
      }
    },
    onMouseDown(event) {
      this.drag = true;
      this.dragStartX = event.clientX - this.x;
      this.dragStartY = event.clientY - this.y;
    },
    onMouseUp() {
      this.drag = false;
      this.dragStartX = this.x;
      this.dragStartY = this.y;
    },
    onMouseWheel(event) {
      if (this.disableWheel || this.contain) {
        return;
      }

      event.preventDefault();

      if (event.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    onMouseMove(event) {
      if (!this.drag || this.contain) {
        return;
      }
      const newX = event.clientX - this.dragStartX;
      const newY = event.clientY - this.dragStartY;

      if ((newX <= 0 || newX < this.x) && (newX >= this.imgOffsetX || newX > this.x)) {
        this.x = newX;
      }
      if ((newY <= 0 || newY < this.y) && (newY >= this.imgOffsetY || newY > this.y)) {
        this.y = newY;
      }
    },
    updateZoom(ratio) {
      const newX = this.x * ratio;
      // const newX = (this.x - this.imgOffsetX / 2) / 2 * ratio;
      if (newX <= 0 && newX > this.imgOffsetX) {
        this.x = newX;
      } else {
        this.x = this.imgOffsetX / 2;
      }

      const newY = this.y * ratio;
      // const newY = (this.y + this.imgOffsetY / 2) / 2 * ratio;
      if (newY <= 0 && newY > this.imgOffsetY) {
        this.y = newY;
      } else {
        this.y = this.imgOffsetY / 2;
      }
    },
  },
});
